/* eslint-disable */
import assignDistributor from '@/views/product/module/assignDistributor/index'

export default {
  components: {
    assignDistributor
  },
  methods: {
    assignDistributor(param) {
      param = Array.isArray(param) ? param : [param]
      console.log(param)
      this.$refs.assignDistributor.dialogOpen(
        param.map(({ id }) => id)
      )
    }
  }
}